/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const GET_TIMELINE_DATA = gql`
  query Timeline($id: String!, $locale: String!) {
    timeline(id: $id, locale: $locale) {
      column1Description {
        json
      }
      column1Icon {
        url
      }
      column1Title {
        json
      }
      column2Description {
        json
      }
      column2Icon {
        url
      }
      column2Title {
        json
      }
      column3Description {
        json
      }
      column3Icon {
        url
      }
      column3Title {
        json
      }
      column4Description {
        json
      }
      column4Icon {
        url
      }
      column4Title {
        json
      }
      cta {
        icon
        internalName(locale: $locale)
        link
        name(locale: $locale)
        style
      }
      description {
        json
      }
      internalName(locale: $locale)
      showTag
      tagColour
      tagText(locale: $locale)
      title {
        json
      }
    }
  }
`;
export const useTimelineCard = (id, locale) => {
  const { data, loading, error } = useQuery(GET_TIMELINE_DATA, {
    variables: { id, locale }
  });

  return { loading, error, data };
};
