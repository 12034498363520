/* eslint-disable monorepo-cop/no-relative-import-outside-package */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery, gql } from '@apollo/client';
import { handleApolloError } from '@exo/frontend-common-apollo';
// eslint-disable-next-line monorepo-cop/no-relative-import-outside-package
import { MediaSplit_50 } from '../../../newcleo-ui/client/components/Shared/MediaSplit50/MediaSplit50';
// eslint-disable-next-line monorepo-cop/no-relative-import-outside-package
import { VideoSection } from '../../../newcleo-ui/client/components/Shared/VideoSection/VideoSection';
import { NewsListingContainer } from '../../../../../components/newcleo/src/NewsListingContainer/NewsListingContainer';
import { InnerHeroBanner } from '../../../../../components/newcleo/src/InnerHeroBanner/InnerHeroBanner';
import { CustomizedHeroBanner } from '../../../newcleo-ui/client/components/CustomizedHeroBanner/CustomizedHeroBanner';
import { JobListingPage } from '../../../newcleo-ui/client/pages/JobListingPage/JobListingPage';
import { FaqCard } from '../../../newcleo-ui/client/components/Shared/FaqCard/FaqCard';
import { QuoteBanner } from '../../../../../components/newcleo/src/QuoteBanner/QuoteBanner';
import { Media_50Desktop } from '../../../../../components/newcleo/src/Media50Desktop/Media50Desktop';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ImageTile } from '../../../../../components/newcleo/src/ImageTile/ImageTile';
import { TimelineCard } from '../../../newcleo-ui/client/components/Shared/TimelineCard/TimelineCard';
import { FiftyBanner } from '../../../../../components/newcleo/src/FiftyBanner/FiftyBanner';
import { TextQuote } from '../../../../../components/newcleo/src/TextQuote/TextQuote';
import { LinkedPageCards } from '../../../../../components/newcleo/src/LinkedPageCards/LinkedPageCards';
import { DocumentDownloadCard } from '../../../../../components/newcleo/src/DocumentDownloadCard/DocumentDownloadCard';
import { LeadershipProfile } from '../../../../../components/newcleo/src/LeadershipProfile/LeadershipProfile';
import { ColumnRichText } from '../../../newcleo-ui/client/components/ColumnRichText/ColumnRichText';
import { NCButton } from '../../../../../components/newcleo/src/NCButton/NCButton';
import { VideoSplit50 } from '../../../newcleo-ui/client/components/Shared/VideoSplit50/VideoSplit50';
import { LatestNewsCard } from '../../../../../components/newcleo/src/LatestNewsCard/LatestNewsCard';
import { ContactUsForm } from '../../../../../components/newcleo/src/ContactUsForm/ContactUsForm';
import { IconsColumn } from '../../../../../components/newcleo/src/IconsColumn/IconsColumn';
import { ContactUsProfile } from '../../../../../components/newcleo/src/ContactUsProfile/ContactUsProfile';
import { ScopedTabs } from '../../../../../components/newcleo/src/ScopedTabs/ScopedTabs';

const GET_LANDING_PAGE = gql`
  query LANDING_PAGE($slug: String!) {
    landingPageCollection(limit: 1, where: { slug: $slug }) {
      items {
        body {
          json
          links {
            entries {
              block {
                sys {
                  id
                }
              }
            }
            assets {
              block {
                sys {
                  id
                }
                url
              }
            }
          }
        }
        slug
        seoTitle
        seoDescription
        canonicalUrl
        keywords
        featuredImage {
          url
        }
        excludeLinksFromSearchRankingsNotfollow
        hidePageFromSearchEnginesNoindex
        stage {
          __typename
          ... on HeroBanner {
            sys {
              id
            }
            ctasCollection(limit: 2) {
              items {
                icon
                link
                name
                style
              }
            }
            description {
              json
            }
            imageWithFocalPoint {
              image {
                url
              }
            }
            name
            opacityOverlay
            sys {
              id
            }
            title {
              json
            }
          }
          ... on InnerHeroBanner {
            sys {
              id
            }
            description {
              json
            }
            name
            opacityOverlay
            title {
              json
            }
          }
          ... on HeroBannerCarousel {
            sys {
              id
            }
          }
        }
      }
    }
    newsArticleCollection(limit: 1, where: { slug: $slug }) {
      items {
        name
        slug
        seoTitle
        seoDescription
        canonicalUrl
        keywords
        featuredImage {
          url
        }
        excludeLinksFromSearchRankingsNotfollow
        hidePageFromSearchEnginesNoindex
      }
    }
    commonInnerPageCollection(limit: 1, where: { slug: $slug }) {
      items {
        body {
          json
          links {
            assets {
              block {
                height
                sys {
                  id
                }
                url
              }
            }
            entries {
              block {
                sys {
                  id
                }
              }
            }
          }
        }
        introText {
          json
        }
        slug
        seoTitle
        seoDescription
        canonicalUrl
        keywords
        featuredImage {
          url
        }
        excludeLinksFromSearchRankingsNotfollow
        hidePageFromSearchEnginesNoindex
        stage {
          ... on HeroBanner {
            sys {
              id
            }
            ctasCollection(limit: 2) {
              items {
                icon
                link
                name
                style
              }
            }
            description {
              json
            }
            imageWithFocalPoint {
              image {
                url
              }
            }
            name
            opacityOverlay
            sys {
              id
            }
            title {
              json
            }
          }
          ... on HeroBannerCarousel {
            sys {
              id
            }
          }
          ... on InnerHeroBanner {
            sys {
              id
            }
            description {
              json
            }
            name
            opacityOverlay
            title {
              json
            }
          }
        }
      }
    }
  }
`;

export const GET_VIDEO = gql`
  query GET_VIDEO($id: String!) {
    video(id: $id) {
      backgroundImage {
        url
      }
      internalName
      showTag
      style
      sys {
        id
      }
      tagColour
      tagText

      title {
        json
      }
      videoUrl
    }
  }
`;

export const GET_IMAGE_BANNER = gql`
  query IMAGE_BANNER($id: String!) {
    imageBanner(id: $id) {
      alignment
      backgroundImage {
        url
      }
      cta {
        icon
        link
        name
        style
      }
      description {
        json
      }
      internalName
      style
      tagColour
      tagText
      sys {
        id
      }
      title {
        json
      }
      variant
      image {
        url
      }
      video {
        url
      }
      showTag
    }
  }
`;
export const GET_VIDEO_BANNER = gql`
  query VIDEO_BANNER($id: String!) {
    videoBanner(id: $id) {
      alignment
      backgroundImage {
        url
      }
      cta {
        icon
        link
        name
        style
      }
      description {
        json
      }
      internalName
      style
      tagColour
      tagText
      sys {
        id
      }
      title {
        json
      }
      video {
        url
      }
      videoUrl
      showTag
    }
  }
`;

const GET_INFO_CARDS = gql`
  query DownloadCards($id: String!) {
    downloadCards(id: $id) {
      downloadCardCollection {
        items {
          cta {
            icon
            link
            name
            style
          }
          description {
            json
          }
          title {
            json
          }
        }
      }
      internalName
      title
    }
  }
`;

const GET_INNER_HERO_BANNER = gql`
  query INNER_HERO_BANNER($id: String!) {
    innerHeroBanner(id: $id) {
      description {
        json
      }
      sys {
        id
      }
      name
      opacityOverlay
      title {
        json
      }
      imageWithFocalPoint {
        focalPoint
        image {
          height
          url
          width
        }
      }
    }
  }
`;

const GET_HERO_BANNER = gql`
  query GET_HERO_BANNER($id: String!) {
    heroBanner(id: $id) {
      ctasCollection {
        items {
          icon
          link
          name
          style
        }
      }
      description {
        json
      }
      imageWithFocalPoint {
        focalPoint
        image {
          height
          url
          width
        }
      }
      video {
        url
      }
      name
      opacityOverlay
      title {
        json
      }
    }
  }
`;

const GET_CAROUSEL_DATA = gql`
  query HeroBannerCarousel($id: String!) {
    heroBannerCarousel(id: $id) {
      heroBannerCollection {
        items {
          ctasCollection {
            items {
              icon
              link
              name
              style
            }
          }
          description {
            json
          }
          imageWithFocalPoint {
            image {
              url
            }
          }
          name
          opacityOverlay
          title {
            json
          }
        }
      }
      name
    }
  }
`;

export const GET_NEWS_LISTING_DATA = gql`
  query GetNewsListingData {
    newsArticleCollection {
      items {
        name
      }
    }
  }
`;

export const GET_FAQ_DATA = gql`
  query GET_FAQ($id: String!) {
    accordion(id: $id) {
      accordionItemsCollection {
        items {
          sectionText {
            json
          }
          title {
            json
          }
        }
      }
      showTag
      tag
      subtitle {
        json
      }
      tag
      tagColour
      title {
        json
      }
    }
  }
`;

export const GET_BANNER_QUOTE_DATA = gql`
  query GET_BANNER_QUOTE($id: String!) {
    quoteBanner(id: $id) {
      author {
        json
      }
      backgroundImage {
        url
      }
      internalName
      opacityOverlay
      quote {
        json
      }
      showTag
      style
      tagColour
      tagText
    }
  }
`;

export const GET_FULL_WITH_BANNER_DATA = gql`
  query FULL_WITH_BANNER($id: String!) {
    fullWidthBanner(id: $id) {
      imageWithFocalPoint {
        focalPoint
        image {
          height
          url
          width
        }
      }
      cta {
        icon
        name
        style
        link
      }
      description {
        json
      }
      internalName
      opacityOverlay
      showTag
      style
      tagColour
      tagText
      title {
        json
      }
      variant
    }
  }
`;

export const GET_IMAGE_TILE_DATA = gql`
  query GET_TILE_IMAGE($id: String!) {
    imageTiles(id: $id) {
      imageCollection {
        items {
          caption {
            json
          }
          image {
            url
          }
          internalName
        }
      }
      internalName
      backgroundColour
    }
  }
`;

export const GET_TIMELINE_DATA = gql`
  query GET_TIMELINE($id: String!) {
    timeline(id: $id) {
      column1Description {
        json
      }
      column1Icon {
        url
      }
      column1Title {
        json
      }
      column2Description {
        json
      }
      column2Icon {
        url
      }
      column2Title {
        json
      }
      column3Description {
        json
      }
      column3Icon {
        url
      }
      column3Title {
        json
      }
      column4Description {
        json
      }
      column4Icon {
        url
      }
      column4Title {
        json
      }
      column5Description {
        json
      }
      column5Icon {
        url
      }
      column5Title {
        json
      }
      column6Description {
        json
      }
      column6Icon {
        url
      }
      column6Title {
        json
      }
      cta {
        icon
        internalName
        link
        name
        style
      }
      description {
        json
      }
      internalName
      showTag
      tagColour
      tagText
      title {
        json
      }
    }
  }
`;

export const GET_TEXT_QUOTE_DATA = gql`
  query GET_TEXT_QUOTE($id: String!) {
    textQuote(id: $id) {
      author {
        json
      }
      name
      quote {
        json
      }
      style
    }
  }
`;

const GET_LINKEDPAGE_CARDS = gql`
  query linkedPageCards($id: String!) {
    linkedPageCards(id: $id) {
      sys {
        id
      }
      linkedPageCardCollection {
        items {
          cta {
            icon
            link
            name
            style
          }
          image {
            url
          }
          internalName
          link {
            title {
              json
            }
            link
          }
        }
      }
      opacityOverlay
      variant
      title
    }
  }
`;

export const GET_PROFILE_LIST = gql`
  query leadershipProfile($id: String!) {
    leadershipProfileList(id: $id) {
      employeeProfileCollection {
        items {
          bio {
            json
          }
          linkedIn
          name
          profileImage {
            url
          }
          role {
            json
          }
          tabFilterCollection {
            items {
              tabTitleDesc {
                json
              }
              tabTitleText {
                json
              }
              title
            }
          }
        }
      }
      title
    }
  }
`;

export const GET_COLUMN_RICH_TEXT = gql`
  query GET_COLUMN_RICH_TEXT($id: String!) {
    columnTextLayout(id: $id) {
      internalName
      column1 {
        json
      }
      column2 {
        json
      }
      variant
      sys {
        id
      }
    }
  }
`;

export const GET_CTA_DATA = gql`
  query CTA($id: String!) {
    cta(id: $id) {
      icon
      link
      name
      style
      sys {
        id
      }
    }
  }
`;

export const GET_CONTACT_US_PROFILE = gql`
  query GET_CONTACT_US_PROFILE($id: String!) {
    contactUsProfile(id: $id) {
      backgroundOverlay
      description {
        json
      }
      title
      email
      message
      name
      privacyPolicy {
        json
      }
      submit {
        icon
        name
        style
      }
      surname
      telephone
    }
  }
`;

export const GET_LATEST_NEWS = catType => {
  return gql`
    query GET_LATEST_NEWS($id: String!) {
      
      latestNews(id: $id) {
        subTitle {
          json
        }
        title {
          json
        }
        cta {
          icon
          link
          name
          style
        }
      }
      newsArticleCollection(
        limit: 2
        order: publishedDate_DESC
        where: { category: { type_contains_all: "${catType}" } }
      ) {
        items {
          title {
            json
          }
          body {
            json
          }
          category {
            name
            color
            textColor
            type
          }
          image {
            title
            url
          }
          publishedDate
          slug
        }
      }
    }
  `;
};

export const GET_SCOPPED_TABS = gql`
  query scopedTabs($id: String!) {
    scopedTabs(id: $id) {
      internalName
      variant
      title {
        json
      }
      cta {
        link
        name
        style
      }
      tabsCollection {
        items {
          tabContent {
            json
          }
          tabTitle {
            json
          }

          actionsCollection {
            items {
              icon
              link
              name
              style
            }
          }
          preferencesCollection {
            items {
              title
              content {
                json
              }
            }
          }
          imageBanner {
            alignment
            backgroundImage {
              url
            }
            cta {
              icon
              link
              name
              style
            }
            description {
              json
            }
            internalName
            style
            tagColour
            tagText
            sys {
              id
            }
            title {
              json
            }
            variant
            image {
              url
            }
            video {
              url
            }
            showTag
          }
        }
      }
    }
  }
`;

export const THREE_COLUMNS_LAYOUT = gql`
  query textLayout($id: String!) {
    textLayout(id: $id) {
      column1 {
        json
      }
      column2 {
        json
      }
      column3 {
        json
      }
    }
  }
`;

export const TEXT_LAYOUT_COLLECTION_QUERY = gql`
  query textLayout($id: String!) {
    textLayout(id: $id) {
      alignment
      backgroundOverlay {
        url
      }
      callToAction1Collection {
        items {
          link
          name
          internalName
          style
          icon
        }
      }
      image1 {
        url
      }
      image2 {
        url
      }
      image3 {
        url
      }

      callToAction2Collection {
        items {
          link
          name
          internalName
          style
          icon
        }
      }
      callToAction3Collection {
        items {
          link
          name
          internalName
          style
          icon
        }
      }
      column1 {
        json
      }
      column2 {
        json
      }
      column3 {
        json
      }
      internalName
      variant
    }
  }
`;

export const GET_FORM_DATA = gql`
  query GETFORMDATA($id: String!) {
    form(id: $id) {
      formSelector
      sys {
        id
      }
    }
  }
`;

export const MappingComponent = (catType: 'investor' | 'website') => {
  return {
    NewsListing: {
      Component: NewsListingContainer,
      renderComponentOnly: true,
      graphql: GET_NEWS_LISTING_DATA,
      transformData: data => ({
        newsArticles: data?.newsArticleCollection?.items,
        newsCategories: data?.categoryCollection?.items
      })
    },
    JobListing: {
      Component: JobListingPage,
      renderComponentOnly: true,
      graphql: GET_NEWS_LISTING_DATA,
      transformData: data => ({
        newsArticles: data?.newsArticleCollection?.items,
        newsCategories: data?.categoryCollection?.items
      })
    },
    ImageBanner: {
      Component: FiftyBanner,
      graphql: GET_IMAGE_BANNER,
      renderComponentOnly: false,
      transformData: data => ({
        variant: data.imageBanner?.variant,
        title: data.imageBanner?.title?.json,
        description: data.imageBanner?.description?.json,
        headlight: data.imageBanner?.internalName,
        backgroundImage: data.imageBanner?.backgroundImage?.url || null,
        video: data.imageBanner?.video?.url || null,
        backgroundColor: data.imageBanner?.style,
        tileImg: data.imageBanner?.image?.url,
        tagColour: data.imageBanner?.tagColour,
        alignment: data.imageBanner?.alignment ? 'right' : 'left',
        showTag: data.imageBanner?.showTag,
        tagText: data.imageBanner?.tagText,
        cta: data.imageBanner?.cta,
        image: data.imageBanner?.image.url,
        style: data.imageBanner?.style
      })
    },
    VideoBanner: {
      Component: VideoSplit50,
      graphql: GET_VIDEO_BANNER,
      renderComponentOnly: false,
      transformData: data => {
        return {
          title: data.videoBanner?.title?.json,
          description: data.videoBanner?.description?.json,
          headlight: data.videoBanner?.internalName,
          backgroundImage: data.videoBanner?.backgroundImage?.url || null,
          videoUrl: data.videoBanner?.videoUrl || null,
          video: data.videoBanner?.video?.url || null,
          backgroundColor: data.videoBanner?.style,
          tagColour: data.videoBanner?.tagColour,
          alignment: data.videoBanner?.alignment,
          showTag: data.videoBanner?.showTag,
          tagText: data.videoBanner?.tagText,
          cta: data.videoBanner?.cta,
          style: data.videoBanner?.style
        };
      }
    },
    Video: {
      Component: VideoSection,
      graphql: GET_VIDEO,
      renderComponentOnly: false,
      transformData: data => ({
        title: data?.video?.title?.json,
        videoURL: data?.video?.videoUrl,
        showTag: data?.video.showTag,
        tagText: data?.video.tagText,
        tagColor: data?.video.tagColour,
        backgroundImage: data?.video?.backgroundImage?.url,
        style: data?.video?.style
      })
    },
    InnerHeroBanner: {
      Component: InnerHeroBanner,
      graphql: GET_INNER_HERO_BANNER,
      renderComponentOnly: false,
      transformData: data => {
        return {
          title: data?.innerHeroBanner?.title?.json,
          description: data?.innerHeroBanner?.description?.json,
          backgroundColor: data?.innerHeroBanner.opacityOverlay,
          backgroundImage: data?.innerHeroBanner?.imageWithFocalPoint?.image?.url,
          focalPointX: data?.innerHeroBanner?.imageWithFocalPoint?.focalPoint?.focalPoint?.x,
          focalPointY: data?.innerHeroBanner?.imageWithFocalPoint?.focalPoint?.focalPoint?.y,
          imageHeight: data?.innerHeroBanner?.imageWithFocalPoint?.image?.height,
          imageWidth: data?.innerHeroBanner?.imageWithFocalPoint?.image?.width
        };
      }
    },
    HeroBannerCarousel: {
      Component: CustomizedHeroBanner,
      graphql: GET_CAROUSEL_DATA,
      transformData: data => {
        const images = [] as any;

        if (data?.heroBannerCarousel?.heroBannerCollection?.items?.length > 0) {
          data?.heroBannerCarousel?.heroBannerCollection?.items.map(singleItem => {
            images.push({
              title: singleItem.title?.json,
              body: singleItem.description?.json,
              backgroundImage: singleItem.imageWithFocalPoint.image?.url,
              opacityOverlay: singleItem.opacityOverlay,
              actions: singleItem.ctasCollection.items,
              imageHeight: singleItem?.imageWithFocalPoint?.image?.height,
              imageWidth: singleItem?.imageWithFocalPoint?.image?.width,
              focalPointX: singleItem?.imageWithFocalPoint?.focalPoint?.focalPoint?.x,
              focalPointY: singleItem?.imageWithFocalPoint?.focalPoint?.focalPoint?.y
            });
          });
        }
        return {
          items: images
        };
      }
    },
    Accordion: {
      Component: FaqCard,
      graphql: GET_FAQ_DATA,
      transformData: data => {
        return {
          title: data?.accordion?.title?.json,
          subtitle: data?.accordion?.subtitle?.json,
          showTag: data?.accordion?.showTag,
          tagName: data?.accordion?.tag,
          tagColor: data?.accordion?.tagColour,
          items: data?.accordion?.accordionItemsCollection?.items
        };
      }
    },
    QuoteBanner: {
      Component: QuoteBanner,
      graphql: GET_BANNER_QUOTE_DATA,
      transformData: data => ({
        title: data?.quoteBanner?.name,
        quote: data?.quoteBanner?.quote?.json,
        author: data?.quoteBanner?.author?.json,
        name: data.quoteBanner?.name,
        tagName: 'Quote',
        style: data.quoteBanner?.style,
        backgroundImage: data.quoteBanner?.backgroundImage?.url,
        internalName: data.quoteBanner?.internalName,
        opacityOverlay: data.quoteBanner?.opacityOverlay,
        showTag: data.quoteBanner?.showTag,
        tagColour: data.quoteBanner?.tagColour,
        tagText: data.quoteBanner?.tagText
      })
    },
    FullWidthBanner: {
      Component: Media_50Desktop,
      graphql: GET_FULL_WITH_BANNER_DATA,
      transformData: data => ({
        cta: data.fullWidthBanner?.cta,
        description: data.fullWidthBanner?.description?.json,
        internalName: data.fullWidthBanner?.internalName,
        opacityOverlay: data.fullWidthBanner?.opacityOverlay,
        showTag: data.fullWidthBanner?.showTag,
        style: data.fullWidthBanner?.style,
        tagColour: data.fullWidthBanner?.tagColour,
        tagText: data.fullWidthBanner?.tagText,
        title: data.fullWidthBanner?.title?.json,
        alignment: data.fullWidthBanner?.variant ? 'right' : 'center',
        backgroundImage: data?.fullWidthBanner?.imageWithFocalPoint?.image?.url,
        imageHeight: data?.fullWidthBanner?.imageWithFocalPoint?.image?.height,
        imageWidth: data?.fullWidthBanner?.imageWithFocalPoint?.image?.width,
        focalPointX: data?.fullWidthBanner?.imageWithFocalPoint?.focalPoint?.focalPoint?.x,
        focalPointY: data?.fullWidthBanner?.imageWithFocalPoint?.focalPoint?.focalPoint?.y
      })
    },
    ImageTiles: {
      Component: ImageTile,
      graphql: GET_IMAGE_TILE_DATA,
      transformData: data => {
        return {
          backgroundColour: data?.imageTiles?.backgroundColour,
          items: data.imageTiles?.imageCollection?.items ?? []
        };
      }
    },
    HeroBanner: {
      Component: CustomizedHeroBanner,
      graphql: GET_HERO_BANNER,
      transformData: data => {
        const images = [] as any;
        images.push({
          title: data?.heroBanner?.title?.json,
          body: data?.heroBanner?.description?.json,
          backgroundImage: data?.heroBanner?.imageWithFocalPoint?.image?.url,
          backgroundVideo: data?.heroBanner?.video?.url,
          opacityOverlay: data?.heroBanner?.opacityOverlay,
          actions: data?.heroBanner?.ctasCollection.items,
          imageHeight: data?.heroBanner?.imageWithFocalPoint?.image?.height,
          imageWidth: data?.heroBanner?.imageWithFocalPoint?.image?.width,
          focalPointX: data?.heroBanner?.imageWithFocalPoint?.focalPoint?.focalPoint?.x,
          focalPointY: data?.heroBanner?.imageWithFocalPoint?.focalPoint?.focalPoint?.y
        });
        return {
          items: images
        };
      }
    },
    Timeline: {
      Component: TimelineCard,
      graphql: GET_TIMELINE_DATA,
      transformData: data => {
        const timelineProps = {
          title: documentToReactComponents(data?.timeline?.title?.json),
          content: documentToReactComponents(data?.timeline?.description?.json),
          cta: data?.timeline?.cta,
          showTag: data?.timeline?.showTag,
          tagColour: data?.timeline?.tagColour,
          tagText: data?.timeline?.tagText,
          items: [
            {
              label: data?.timeline?.column1Title
                ? documentToReactComponents(data.timeline.column1Title?.json)
                : null,
              description: data?.timeline?.column1Description
                ? documentToReactComponents(data.timeline.column1Description?.json)
                : null,
              icon: data?.timeline?.column1Icon?.url || null
            },
            {
              label: data?.timeline?.column2Title
                ? documentToReactComponents(data.timeline.column2Title?.json)
                : null,
              description: data?.timeline?.column2Description
                ? documentToReactComponents(data.timeline.column2Description?.json)
                : null,
              icon: data?.timeline?.column2Icon?.url || null
            },
            {
              label: data?.timeline?.column3Title
                ? documentToReactComponents(data.timeline.column3Title?.json)
                : null,
              description: data?.timeline?.column3Description
                ? documentToReactComponents(data.timeline.column3Description?.json)
                : null,
              icon: data?.timeline?.column3Icon?.url || null
            },
            {
              label: data?.timeline?.column4Title
                ? documentToReactComponents(data.timeline.column4Title?.json)
                : null,
              description: data?.timeline?.column4Description
                ? documentToReactComponents(data.timeline.column4Description?.json)
                : null,
              icon: data?.timeline?.column4Icon?.url || null
            },
            {
              label: data?.timeline?.column5Title
                ? documentToReactComponents(data.timeline.column5Title?.json)
                : null,
              description: data?.timeline?.column5Description
                ? documentToReactComponents(data.timeline.column5Description?.json)
                : null,
              icon: data?.timeline?.column5Icon?.url || null
            },
            {
              label: data?.timeline?.column6Title
                ? documentToReactComponents(data.timeline.column6Title?.json)
                : null,
              description: data?.timeline?.column6Description
                ? documentToReactComponents(data.timeline.column6Description?.json)
                : null,
              icon: data?.timeline?.column6Icon?.url || null
            }
          ]
        };
        return timelineProps;
      }
    },
    Image: {
      Component: MediaSplit_50,
      graphql: GET_IMAGE_BANNER,
      renderComponentOnly: false,
      transformData: data => ({
        title: data.imageBanner?.title?.json.content[0]?.content[0]?.value,
        tagText: data.imageBanner.tagText,
        subTitle: data.imageBanner?.description?.json.content[0].content[0].value,
        headlight: data.imageBanner?.internalName,
        ctaLink: data.imageBanner?.cta.link,
        ctaIcon: data.imageBanner?.cta.icon,
        ctaText: data.imageBanner?.cta.name,
        ctaStyle: data.imageBanner?.cta.style,
        backgroundImage: data.imageBanner?.backgroundImage?.url,
        backgroundColor: data.imageBanner?.style,
        tileImg: data.imageBanner?.image.url,
        tagColour: data.imageBanner?.tagColour,
        position: data.imageBanner?.alignment ? 'right' : 'left',
        mode: data.imageBanner?.style,
        showTag: data.imageBanner?.showTag
      })
    },
    TextQuote: {
      Component: TextQuote,
      graphql: GET_TEXT_QUOTE_DATA,
      transformData: data => ({
        quote: data?.textQuote?.quote?.json,
        author: data?.textQuote?.author?.json,
        style: data.textQuote?.style
      })
    },
    LinkedPageCards: {
      Component: LinkedPageCards,
      graphql: GET_LINKEDPAGE_CARDS,
      renderComponentOnly: false,
      transformData: data => {
        const items = data?.linkedPageCards.linkedPageCardCollection?.items;
        return {
          title: data?.linkedPageCards?.internalName,
          opacityOverlay: data.linkedPageCards?.opacityOverlay,
          variant: data.linkedPageCards.variant ? 'large' : 'portrait',
          items
        };
      }
    },
    LeadershipProfileList: {
      Component: LeadershipProfile,
      graphql: GET_PROFILE_LIST,
      renderComponentOnly: false,
      transformData: data => {
        return {
          items: data?.leadershipProfileList?.employeeProfileCollection?.items,
          title: data?.leadershipProfileList?.title
        };
      }
    },
    DownloadCards: {
      Component: DocumentDownloadCard,
      graphql: GET_INFO_CARDS,
      renderComponentOnly: false,
      transformData: data => {
        return {
          title: data.downloadCards?.title,
          items: data.downloadCards?.downloadCardCollection.items
        };
      }
    },
    ColumnTextLayout: {
      Component: ColumnRichText,
      graphql: GET_COLUMN_RICH_TEXT,
      transformData: data => {
        return {
          column_1: data?.columnTextLayout?.column1?.json,
          column_2: data?.columnTextLayout?.column2?.json,
          bgColor: data?.columnTextLayout?.variant == 'Grey' ? '#373737' : 'white'
        };
      }
    },
    Cta: {
      Component: NCButton,
      graphql: GET_CTA_DATA,
      transformData: data => {
        return {
          label: data?.cta?.name,
          variant: data?.cta?.style,
          href: data?.cta?.link,
          icon: data?.cta?.icon
        };
      }
    },
    LatestNews: {
      Component: LatestNewsCard,
      graphql: GET_LATEST_NEWS(catType),
      renderComponentOnly: false,
      transformData: data => {
        return {
          title: data?.latestNews?.title?.json,
          description: data?.latestNews?.subTitle?.json,
          ctaText: data?.latestNews?.cta?.name,
          icon: data?.latestNews?.cta?.icon,
          ctaLink: data?.latestNews?.cta?.link,
          variant: data?.latestNews?.cta?.style,
          items: data?.newsArticleCollection?.items
        };
      }
    },
    ScopedTabs: {
      Component: ScopedTabs,
      graphql: GET_SCOPPED_TABS,
      renderComponentOnly: false,
      transformData: data => {
        const items = [...data?.scopedTabs?.tabsCollection?.items]?.map(item => {
          let obj = {
            ...item
          };

          if (item?.imageBanner !== null)
            obj.imageBanner = {
              variant: item?.imageBanner?.variant,
              title: item?.imageBanner?.title?.json,
              description: item?.imageBanner?.description?.json,
              headlight: item?.imageBanner?.internalName,
              backgroundImage: item?.imageBanner?.backgroundImage?.url,
              video: item?.imageBanner?.video?.url,
              backgroundColor: item?.imageBanner?.style,
              tileImg: item?.imageBanner?.image?.url,
              tagColour: item?.imageBanner?.tagColour,
              alignment: item?.imageBanner?.alignment,
              showTag: item?.imageBanner?.showTag,
              tagText: item?.imageBanner?.tagText,
              cta: item?.imageBanner?.cta,
              image: item?.imageBanner?.image?.url,
              style: item?.imageBanner?.style
            };
          else {
            delete obj.imageBanner;
          }

          return obj;
        });

        return {
          internalName: data?.scopedTabs.internalName,
          title: data?.scopedTabs?.title,
          cta: data?.scopedTabs?.cta,
          variant: data?.scopedTabs?.variant,
          items: items
        };
      }
    },
    TextLayout: {
      Component: IconsColumn,
      graphql: TEXT_LAYOUT_COLLECTION_QUERY,
      renderComponentOnly: false,
      transformData: data => {
        let items = [
          {
            cta: data.textLayout?.callToAction1,
            content: data.textLayout?.column1?.json,
            callToActionCollection: data.textLayout?.callToAction1Collection,
            icon: data.textLayout?.image1?.url
          },
          {
            cta: data.textLayout?.callToAction2,
            content: data.textLayout?.column2?.json,
            callToActionCollection: data.textLayout?.callToAction2Collection,
            icon: data.textLayout?.image2?.url
          },
          {
            cta: data.textLayout?.callToAction3,
            content: data.textLayout?.column3?.json,
            callToActionCollection: data.textLayout?.callToAction3Collection,
            icon: data.textLayout?.image3?.url
          }
        ];

        return {
          items,
          variant: data.textLayout?.variant,
          internalName: data.textLayout?.internalName,
          backgroundOverlay: data?.textLayout?.backgroundOverlay?.url,
          alignment: data?.textLayout?.alignment
        };
      }
    },
    Form: {
      Component: ContactUsForm,
      graphql: GET_FORM_DATA,
      transformData: data => ({
        variant: data?.form?.formSelector
      })
    },
    ContactUsProfile: {
      Component: ContactUsProfile,
      graphql: GET_CONTACT_US_PROFILE,
      renderComponentOnly: false,
      transformData: data => {
        const contactForm = {
          email: data?.contactUsProfile?.email,
          message: data?.contactUsProfile?.message,
          name: data?.contactUsProfile?.name,
          privacyPolicy: data?.contactUsProfile?.privacyPolicy?.json,
          submit: data?.contactUsProfile?.submit,
          surname: data?.contactUsProfile?.surname
        };

        return {
          title: data?.contactUsProfile?.title,
          content: data?.contactUsProfile?.description?.json,
          backgroundOverlay: data?.contactUsProfile.backgroundOverlay[0],
          contactForm
        };
      }
    }
  };
};

export const useDynamicPage = ({ slug }: Args) => {
  const variables = { slug };

  const { called, loading, data, error } = useQuery(GET_LANDING_PAGE, { variables });

  handleApolloError(__filename, error);

  return { called, loading, data: data, error };
};

type Args = {
  slug: string;
};
