/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const GET_DOWNLOAD_CARDS = gql`
  query InfoCard($id: String!, $locale: String!) {
    infoCard(id: $id, locale: $locale) {
      cta {
        icon
        internalName(locale: $locale)
        link
        name
        style
      }
      description {
        json
      }
      name(locale: $locale)
      title {
        json
      }
    }
  }
`;
export const useDownloadCards = (id, locale) => {
  const { data, loading, error } = useQuery(GET_DOWNLOAD_CARDS, {
    variables: { id, locale }
  });

  return { loading, error, data };
};
