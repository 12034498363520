/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import {
  renderDefaultError,
  renderDefaultLoading,
  SmartComponentProps
} from '@exo/frontend-common-utils';
import { NewsArticle } from '../../types';
import { useLatestNews } from '../../hooks/useLatestNews';

export const GetLatestNewsArticles = ({
  render,
  renderLoading = renderDefaultLoading,
  renderError = renderDefaultError
}: Props) => {
  const { loading, data, error } = useLatestNews();

  if (loading || !data) return renderLoading();
  if (error) return renderError(error);

  return render({ data: data as NewsArticle[] });
};

type Props = SmartComponentProps<{
  newArticlePage?: Boolean;
  render: (props: { data: NewsArticle[] }) => JSX.Element;
}>;
