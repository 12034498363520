/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import { gql, useQuery } from '@apollo/client';

const GET_LINKED_PAGE_CARDS_DATA = gql`
  query linkedPageCards($id: String!, $locale: String!) {
    linkedPageCards(id: $id, locale: $locale) {
      internalName(locale: $locale)
      opacityOverlay
      variant
    }
    linkedPageCardsCollection {
      items {
        internalName(locale: $locale)
        linkedPageCardCollection {
          items {
            cta {
              icon
              link
              name
              style
            }
            image {
              title(locale: $locale)
              url
            }
            internalName(locale: $locale)
          }
        }
        opacityOverlay
        title(locale: $locale)
        variant
      }
    }
  }
`;
export const useLinkedPageCards = (id, locale) => {
  const { data, loading, error } = useQuery(GET_LINKED_PAGE_CARDS_DATA, {
    variables: { id, locale }
  });

  return { loading, error, data };
};
