/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';

const GET_FOOTER_DETAILS = gql`
  query FOOTER_DETAILS($footerId: String!, $locale: String) {
    footer(id: $footerId) {
      companyDetails {
        json
      }
      copyrightText {
        json
      }
      footerLinks1Collection {
        items {
          link(locale: $locale)
          externalUrl
          name(locale: $locale)
          title {
            json
          }
        }
      }
      footerLinks2Collection {
        items {
          link(locale: $locale)
          externalUrl
          title {
            json
          }
          name(locale: $locale)
        }
      }
      footerLinks3Collection {
        items {
          link(locale: $locale)
          externalUrl
          title {
            json
          }
          name(locale: $locale)
        }
      }
      footerSocialLinksCollection {
        items {
          icon {
            title(locale: $locale)
            url(locale: $locale)
          }
          link(locale: $locale)
        }
      }
      logo {
        contentType(locale: $locale)
        url(locale: $locale)
      }
      name(locale: $locale)
      policyLinksCollection {
        items {
          link(locale: $locale)
          name(locale: $locale)
          externalUrl
        }
      }
      socialLinksTitle(locale: $locale)
    }
  }
`;

function TransformFooterLinks(footerLinks) {
  const footerLinksItems: Array<any> = [];
  footerLinks.map(singleItem => {
    const link = {
      title: singleItem.title,
      links: singleItem.items.map(singleLinkItem => ({
        name: singleLinkItem.name,
        title: singleLinkItem?.title.json,
        link: singleLinkItem.link
      }))
    };
    footerLinksItems.push(link);
  });
  return footerLinksItems;
}

function TransformSocialLinks(socialLinks: Array<any>) {
  const links: Array<any> = [];
  socialLinks.map(singleLink => {
    const link = {
      logo: singleLink.icon.url,
      link: singleLink.link
    };
    links.push(link);
  });
  return links;
}

const transformResponse = (data: any) => {
  if (data) {
    const transformedData = structuredClone(data);
    transformedData.footer.originalCompanyDetails = data?.footer?.companyDetails?.json;
    // transformedData.footer.companyDetails = convertToSimpleText(companyBusiness);
    transformedData.footer.copyrightText = transformedData.footer?.copyrightText?.json;
    transformedData.footer.footerLinks = TransformFooterLinks([
      {
        title: 'Home',
        items: transformedData.footer.footerLinks1Collection.items
      },
      {
        title: 'Newcleo Group',
        items: transformedData.footer.footerLinks2Collection.items
      },
      {
        title: 'Global',
        items: transformedData.footer.footerLinks3Collection.items
      }
    ]);
    transformedData.footer.footerSocialLinksCollection = TransformSocialLinks(
      transformedData.footer.footerSocialLinksCollection.items
    );

    return transformedData;
  }
};

export const useFooter = <T>({ footerId, locale }: Args): Result<T> => {
  const variables = { footerId, locale };

  const { called, loading, data, error } = useQuery(GET_FOOTER_DETAILS, { variables });

  handleApolloError(__filename, error);

  return { called, loading, data: transformResponse(data), error };
};

type Args = {
  footerId: string;
  locale: string;
};

type Result<T> = {
  data?: T;
  called: boolean;
} & State;
