/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const GET_LEADERSHIP_TEAM = gql`
  query video($id: String!, $locale: String!) {
    video(id: $id, locale: $locale) {
      backgroundImage {
        url
      }
      internalName(locale: $locale)
      showTag
      style
      tagColour
      tagText(locale: $locale)
      title {
        json
      }
      videoUrl
    }
  }
`;

export const useLeadershipTeam = (id, locale) => {
  const { data, loading, error } = useQuery(GET_LEADERSHIP_TEAM, { variables: { id, locale } });

  return { loading, error, data };
};
