/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const GET_QUOTE = gql`
  query Quote($id: String!, $locale: String!) {
    quoteBanner(id: $id, locale: $locale) {
      author {
        json
      }
      backgroundImage {
        url
      }
      internalName(locale: "en-US")
      opacityOverlay
      quote {
        json
      }
      showTag
      style
      tagColour
      tagText(locale: "en-US")
    }
  }
`;

export const useQuote = (id, locale) => {
  const { data, loading, error } = useQuery(GET_QUOTE, { variables: { id, locale } });

  return { loading, error, data };
};
