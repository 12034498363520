/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const GET_HERO_BANNER = gql`
  query InnerHeroBanner($id: String!, $locale: String!) {
    innerHeroBanner(id: $id) {
      image {
        url
      }
      name(locale: $locale)
      description {
        json
      }
      opacityOverlay
      title {
        json
      }
    }
  }
`;

export const useCommunInnerHeroBanner = (id, locale) => {
  const { data, loading, error } = useQuery(GET_HERO_BANNER, { variables: { id, locale } });

  return { loading, error, data };
};
