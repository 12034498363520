/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery } from '@apollo/client';
import { assembleQuery, Fragments, handleApolloError, State } from '@exo/frontend-common-apollo';

const transformResponse = <T>(data: any): T => {
  return data;
};

export const useNewsArticleDetail = <T>({ slug }: Args, fragments: Fragments): Result<T> => {
  const variables = { slug };

  const { called, loading, data, error } = useQuery(
    assembleQuery(
      fragmentNames => `
        query Query($slug: String, $limit: Int) {
          newsArticleCollection(where: {slug: $slug}, limit: $limit) {
            items {
              ${fragmentNames}
            }
          }
        }
      `,
      fragments
    ),
    { variables: { ...variables, limit: 1 } }
  );

  handleApolloError(__filename, error);

  return {
    called,
    loading,
    data: data ? transformResponse<T>(data.newsArticleCollection.items[0]) : undefined,
    error
  };
};

type Args = {
  slug: string;
};

type Result<T> = {
  data?: T;
  called: boolean;
} & State;
