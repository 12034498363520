/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useMemo, useState } from 'react';
import * as S from './ContactUsProfile.styles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { gql, useQuery } from '@apollo/client';
import { Grid } from '@exo/frontend-components-base';
import { ProfileCard } from '../ProfileCard/ProfileCard';
import { Checkbox } from '@carbon/react';
import { NCButton } from '../NCButton/NCButton';
import { SubmitHandler, useForm } from 'react-hook-form';
// eslint-disable-next-line monorepo-cop/no-relative-import-outside-package
import { saveContactUsApi } from '../../../../features/newcleo/newcleo-logic/client/api-call';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useTheme } from 'styled-components';
import { useMsal } from '@azure/msal-react';

const GET_PROFILE = gql`
  query leadershipProfile($id: String!) {
    leadershipProfile(id: $id) {
      bio {
        json
      }
      name
      profileImage {
        url
      }
      role {
        json
      }
    }
  }
`;

const ProfileCards = ({ sysId }) => {
  const { loading, data } = useQuery(GET_PROFILE, {
    variables: { id: sysId }
  });

  if (loading) return <></>;
  else {
    const profile = data?.leadershipProfile;
    return (
      <>
        <ProfileCard
          image={profile.profileImage.url}
          name={profile.name}
          bio={documentToReactComponents(profile.bio.json)}
          linkedIn={profile.linkedIn}
          role={documentToReactComponents(profile.role.json)}
        />
      </>
    );
  }
};

export const ContactUsProfile = ({ title, content, backgroundOverlay, contactForm }: Props) => {
  const [isvalid, setIsValid] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const theme = useTheme();
  const { instance } = useMsal();
  const activeAcount = instance.getActiveAccount();

  const schema = yup
    .object({
      name: yup.string().required(),
      surname: yup.string().required('Surname is required'),
      email: yup.string().email('Email Adress not valid').required('Email Adress is required'),
      message: yup.string().max(500).required('Message is required')
    })
    .required();

  const form = useForm({
    resolver: yupResolver(schema)
  });

  const user = useMemo(() => {
    let myAccount = {} as any;
    if (activeAcount && Object.keys(activeAcount).length > 0) {
      myAccount = activeAcount;
    } else {
      myAccount = JSON.parse(localStorage.getItem('activeAcount') || '{}') as any;
    }

    if (Object.keys(myAccount).length > 0) {
      return {
        name: myAccount?.idTokenClaims?.givenName || '',
        surname: myAccount?.idTokenClaims?.surname || '',
        email: myAccount?.username
      };
    }
    return { name: '', surname: '', email: '' };
  }, [activeAcount]);

  type Inputs = {
    name: string;
    surname: string;
    email: string;
    message: string;
  };

  const onSubmit: SubmitHandler<Inputs> = data => {
    saveContactUsApi(data)
      .then(e => {
        toast.success(
          <span>
            Thank you <b>{e.name}</b> <br />
            Your message has been successfully submited
          </span>,
          {
            style: {
              borderLeft: `3px solid #42be65`,
              borderRadius: '2px',
              backgroundColor: theme.colors.backgrounds['$background-solid-grey'],
              color: theme.colors.text['$text-inverse'],
              fontSize: '1rem'
            },
            duration: 3500
          }
        );
        form.reset({ message: '' });
        setMessage('');
        setIsValid(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const TextInput = ({ type, labelText, error, input, defaultValue, readOnly }) => {
    return (
      <S.TextInput className={`${type}`}>
        <label>{labelText}</label>
        {type === 'input' && (
          <input
            defaultValue={defaultValue}
            readOnly={readOnly}
            className={`form-control ${readOnly ? 'read-only' : ''}`}
            {...form.register(input)}
            aria-invalid={error ? 'true' : 'false'}
          />
        )}
        {type === 'textarea' && (
          <textarea
            className={`form-control`}
            {...form.register(input)}
            aria-invalid={error ? 'true' : 'false'}
            onMouseMove={(e: any) => {
              setMessage(e.target.value);
            }}
          />
        )}

        <S.Error>
          {form.formState.errors[input] && (
            <p role="alert">{form.formState.errors[input].message}</p>
          )}
        </S.Error>
      </S.TextInput>
    );
  };

  const ContactForm = () => (
    <form>
      <S.Form>
        <TextInput
          type="input"
          labelText={contactForm?.name}
          input={'name'}
          error={form.formState.errors.name}
          defaultValue={user?.name}
          readOnly
        />
        <TextInput
          type="input"
          labelText={contactForm?.surname}
          input={'surname'}
          error={form.formState.errors.surname}
          defaultValue={user?.surname}
          readOnly
        />
        <TextInput
          type="input"
          labelText={contactForm?.email}
          input={'email'}
          error={form.formState.errors.email}
          defaultValue={user?.email}
          readOnly={true}
        />
        <TextInput
          type="textarea"
          labelText={contactForm?.message}
          input={'message'}
          error={form.formState.errors.message}
          defaultValue={undefined}
          readOnly={false}
        />

        <Checkbox
          onChange={e => {
            setIsValid(e.target.checked);
          }}
          checked={isvalid}
          className="checkbox"
          id="privacy-policy"
          labelText={documentToReactComponents(contactForm?.privacyPolicy)}
        />

        <NCButton
          disabled={!(message && isvalid)}
          onClick={form.handleSubmit(onSubmit)}
          variant={contactForm?.submit.style}
          icon={contactForm?.submit.icon}
          label={contactForm?.submit.name}
        />
      </S.Form>
    </form>
  );

  return (
    <S.ContactUsProfile title={title} backgroundOverlay={backgroundOverlay}>
      <Grid>
        {title !== 'investor_contact_us_form' && (
          <>
            <S.Title> {title}</S.Title>
            <S.Content>{documentToReactComponents(content)}</S.Content>
            <S.Row className="profile-cards">
              {content.content.map((item: any) => {
                if (item.nodeType === 'embedded-entry-block') {
                  return (
                    <ProfileCards
                      key={item?.data?.target?.sys?.id}
                      sysId={item?.data?.target?.sys?.id}
                    />
                  );
                }
                return '';
              })}
            </S.Row>
          </>
        )}
        {title === 'investor_contact_us_form' && <ContactForm />}
      </Grid>
    </S.ContactUsProfile>
  );
};

type Props = {
  title: string;
  content: any;
  backgroundOverlay: string;
  contactForm?: {
    email: string;
    message: string;
    name: string;
    privacyPolicy: any;
    submit: any;
    surname: string;
    telephone: string;
  };
};
