/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const GET_CAROUSEL_DATA = gql`
  query HeroBannerCarousel($id: String!, $locale: String!) {
    heroBannerCarousel(id: $id) {
      heroBannerCollection {
        items {
          ctasCollection {
            items {
              icon
              link
              name(locale: $locale)
              style
            }
          }
          description {
            json
          }
          image {
            url
          }
          name(locale: $locale)
          opacityOverlay
          title {
            json
          }
        }
      }
      name(locale: $locale)
    }
  }
`;

export const useHeroCarousel = (id, locale) => {
  const { data, loading, error } = useQuery(GET_CAROUSEL_DATA, { variables: { id, locale } });

  return { loading, error, data };
};
