/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const GET_50MEDIUM_BANNER = gql`
  query ImageBanner($id: String!, $locale: String!) {
    imageBanner(id: $id) {
      alignment
      backgroundImage {
        url
      }
      cta {
        icon
        link
        name(locale: $locale)
        style
      }
      description {
        json
      }
      image {
        url
      }
      style
      internalName(locale: $locale)
      title {
        json
      }
      variant(locale: $locale)
    }
  }
`;
export const use50MediumBanner = (id, locale) => {
  const { data, loading, error } = useQuery(GET_50MEDIUM_BANNER, {
    variables: { id, locale }
  });

  return { loading, error, data };
};
